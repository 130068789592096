import React, { useCallback, useContext } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Transition } from "react-transition-group"
import { CursorContext } from "@src/contexts/CursorContext"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  z-index: ${props => props.theme.zIndices.controls};
`

const Overlay = styled.div`
  background-color: ${props => props.theme.colors.overlay};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const Panel = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  max-height: 100%;
  border: ${props => props.theme.borders.controls};
  background-color: ${props => props.theme.colors.controls};
  color: white;
  padding: ${props => props.theme.space.large};
  margin: 40px;
  z-index: 2;

  text-align: center;

  * {
    text-align: center;
  }

  h3 {
    text-transform: uppercase;
    font-weight: normal;
    font-size: ${props => props.theme.fontSizes.subtitle};
    margin: 0;
    padding-top: 10px;
  }

  h4 {
    font-weight: normal;
    font-size: ${props => props.theme.fontSizes.subtitle};
    margin: 0;
  }

  h3,
  h4 {
    @media (max-width: ${props => props.theme.breakpoints.small}) {
      font-size: 20px;
    }
  }

  p {
    margin: 4px 0;
    font-size: ${props => props.theme.fontSizes.default};

    @media (max-width: ${props => props.theme.breakpoints.small}) {
      font-size: 14px;
    }
  }
`
const AudioButton = styled.button`
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 0;
  outline: none;
  padding: 10px 40px 6px 40px;
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes.default};
  white-space: nowrap;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    font-size: 14px;
  }

  color: white;
  border: 4px solid white;

  &:not(.isOn):hover {
    background-color: white;
    color: ${props => props.theme.colors.controls};
    transition: all 200ms;
  }

  &.isOn {
    border: 4px solid transparent;
    font-weight: bold;
  }
`

const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  border-radius: 0;
  padding: 10px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  background-color: transparent;
  outline: none;
`

const Tutorial = ({ onClickAudio, audioEnabled, onCloseControls, ...rest }) => {
  const { setMessage } = useContext(CursorContext)

  const onMouseEnter = useCallback(() => {
    setMessage("close")
  }, [setMessage])

  const onMouseLeave = useCallback(() => {
    setMessage("")
  }, [setMessage])

  return (
    <Wrapper {...rest}>
      <Overlay
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onCloseControls}
      />
      <Panel>
        <Close onClick={onCloseControls}>
          <svg
            id="Capa_1"
            enableBackground="new 0 0 386.667 386.667"
            height="512"
            viewBox="0 0 386.667 386.667"
            width="512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="m386.667 45.564-45.564-45.564-147.77 147.769-147.769-147.769-45.564 45.564 147.769 147.769-147.769 147.77 45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z" />
          </svg>
        </Close>
        <h3>Controls</h3>
        <p>
          in order to enjoy the full experience
          <br />
          please turn your sound on and display on monitor
        </p>
        <AudioButton
          onClick={onClickAudio}
          className={audioEnabled ? "isOn" : "isOff"}
        >
          {audioEnabled ? "Sound is active" : "Activate sound"}
        </AudioButton>
        <h4>Keys</h4>
        <p>M : Sound</p>
        <p>UP : More info</p>
        <p>DOWN : Shop</p>
        <p>RIGHT : Next</p>
        <p>LEFT : Previous</p>
        <p>SPACE : Pause</p>
        <p>+ : Zoom</p>
        <p>C : Controls</p>
      </Panel>
    </Wrapper>
  )
}

Tutorial.propTypes = {
  onClickAudio: PropTypes.func,
  audioEnabled: PropTypes.bool,
}

export default Tutorial
