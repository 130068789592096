import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import Tutorial from "@src/components/Tutorial"
import Controls from "@src/components/Controls"
import Player from "@src/components/Player"
import { LoadingContext } from "@src/contexts/LoadingContext"
import { MediaContext } from "@src/contexts/MediaContext"
import { Transition } from "react-transition-group"
import styled from "@emotion/styled"
import { CursorContext } from "@src/contexts/CursorContext"
import { graphql } from "gatsby"
import { navigate } from "gatsby"
import navigateSoundURL from "@src/assets/sounds/5- Panneau de controls Fx StartUp.mp3"
import introSoundURL from "@src/assets/sounds/2- Jingle Logo Fx Whaou.mp3"

const IndexControls = styled(Controls)`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
`

const IndexPlayerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: #2d2c2d;

  &.isZoom {
    transform: scale(1.6) translateY(12%);
  }
`

const TutorialTransition = {
  entering: {
    opacity: 0,
  },
  entered: {
    transition: `opacity 250ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity 250ms ease-in-out`,
    opacity: 0,
  },
  exited: {
    display: "none",
  },
}

const IndexPage = ({ location, data }) => {
  // Data
  const [intro] = useState({
    poster: data.introPoster.publicURL,
    mp4: data.introMP4.publicURL,
    webm: data.introWebm.publicURL,
  })
  const [lookbooks] = useState(
    data.lookbooks.nodes.map(n => ({
      ...n.frontmatter,
      poster: n.frontmatter.poster.publicURL,
      mp4: n.frontmatter.mp4.publicURL,
      webm: n.frontmatter.webm.publicURL,
    }))
  )
  const preloads = useRef([])

  // Context
  const { stopLoading, startLoading } = useContext(LoadingContext)
  const {
    muted,
    mute,
    unmute,
    audioEnabled,
    authorizeAudio,
    playAmbiant,
    playSample,
  } = useContext(MediaContext)
  const { setMessage } = useContext(CursorContext)

  // States
  const [play, setPlay] = useState(false)
  const [currentSource, setCurrentSource] = useState({})
  const [isTutorial, setIsTutorial] = useState(false)
  const [isIntro, setIsIntro] = useState(false)
  const [isTransition, setIsTransition] = useState(false)
  const [isZoom, setIsZoom] = useState(false)

  // Actions
  const nextLookbook = useCallback(() => {
    const index = lookbooks.indexOf(currentSource) + 1
    setCurrentSource(lookbooks[index > lookbooks.length - 1 ? 0 : index])
  }, [currentSource, setCurrentSource, lookbooks])

  const previousLookbook = useCallback(() => {
    const index = lookbooks.indexOf(currentSource) - 1
    setCurrentSource(lookbooks[index < 0 ? lookbooks.length - 1 : index])
  }, [currentSource, setCurrentSource, lookbooks])

  const openTutorial = useCallback(() => {
    setIsTutorial(true)
  }, [setIsTutorial])

  const closeTutorial = useCallback(() => {
    setIsTutorial(false)
    if (!isIntro) playAmbiant()
  }, [isIntro, setIsTutorial, playAmbiant])

  const playVideo = useCallback(() => {
    setPlay(true)
    if (isIntro) setTimeout(() => playSample(introSoundURL), 200)
  }, [setPlay, playSample, isIntro])

  const pauseVideo = useCallback(() => {
    setPlay(false)
  }, [setPlay])

  const zoomVideo = useCallback(() => {
    setIsZoom(true)
  }, [])

  const unzoomVideo = useCallback(() => {
    setIsZoom(false)
  }, [])

  const goLexique = useCallback(() => {
    startLoading()
    navigate("/lexique", { state: { slug: currentSource.slug } })
  }, [startLoading, currentSource])

  const muteSound = useCallback(() => {
    if (muted) unmute()
    else mute()
  }, [muted, mute, unmute])

  // Events callbacks
  const onLoadeddata = useCallback(() => {
    const preloadImage = src =>
      new Promise(r => {
        const image = new Image()
        image.onload = r
        image.onerror = r
        image.src = src
      })

    const posters = lookbooks.map(l => l.poster)

    Promise.all(posters.map(p => preloadImage(p))).then(() => stopLoading())
  }, [lookbooks, stopLoading])

  const onEnded = useCallback(() => {
    if (isIntro) {
      setPlay(false)
      setIsIntro(false)
      nextLookbook()
      playAmbiant()
    }
  }, [isIntro, setPlay, setIsIntro, nextLookbook, playAmbiant])

  const onMouseEnter = useCallback(() => {
    if (!isIntro) setMessage("preorder")
  }, [isIntro, setMessage])

  const onMouseLeave = useCallback(() => {
    setMessage("")
  }, [setMessage])

  // Effects
  useEffect(() => {
    const ni = lookbooks.indexOf(currentSource) + 1
    const nextLookbook = lookbooks[ni > lookbooks.length - 1 ? 0 : ni]
    const pi = lookbooks.indexOf(currentSource) - 1
    const prevLookbook = lookbooks[pi < 0 ? lookbooks.length - 1 : pi]

    if (preloads.current.indexOf(nextLookbook.id) < 0) {
      fetch(
        Modernizr.video.webm === "probably"
          ? nextLookbook.webm
          : nextLookbook.mp4
      ).then(() => preloads.current.push(nextLookbook.id))
    }

    if (preloads.current.indexOf(prevLookbook.id) < 0) {
      fetch(
        Modernizr.video.webm === "probably"
          ? prevLookbook.webm
          : prevLookbook.mp4
      ).then(() => preloads.current.push(prevLookbook.id))
    }
  }, [currentSource, lookbooks])

  useEffect(() => {
    const slug =
      (location.state && location.state.slug) ||
      location.pathname.split("/").pop()

    const startSource = lookbooks.find(l => l.slug === slug)

    setIsIntro(!startSource)
    setIsTutorial(!startSource)

    setCurrentSource(startSource || intro)
  }, [setCurrentSource, setIsIntro, lookbooks])

  // Preorder
  const preorderAction = useCallback(() => {
    window.open("https://shop.sample-cm.com", '_blank').focus();
  }, [])

  return (
    <div>
      <Transition
        in={isTutorial}
        timeout={300}
        onEntered={pauseVideo}
        onExited={playVideo}
      >
        {state => (
          <Tutorial
            style={TutorialTransition[state]}
            audioEnabled={audioEnabled}
            onClickAudio={authorizeAudio}
            onCloseControls={closeTutorial}
          />
        )}
      </Transition>
      <IndexPlayerWrapper
        className={isZoom ? "isZoom" : ""}
        style={{ cursor: !isIntro ? "pointer" : "" }}
        onClick={!isIntro ? preorderAction : () => {}}
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Player
          play={play}
          source={currentSource}
          timeout={200}
          loop={!isIntro}
          muted={muted}
          onLoadeddata={onLoadeddata}
          onEnded={onEnded}
          onEntering={() => {
            pauseVideo()
            setIsTransition(true)
          }}
          onEntered={() => {
            if (!isIntro) playVideo()
            setIsTransition(false)
          }}
        />
      </IndexPlayerWrapper>
      <Transition
        in={!isIntro && !isTutorial}
        timeout={200}
        onEntered={() => playSample(navigateSoundURL)}
      >
        {state => (
          <IndexControls
            disabled={isTransition}
            muted={muted}
            preorderAction={preorderAction}
            style={TutorialTransition[state]}
            contolsAction={openTutorial}
            pauseAction={pauseVideo}
            playAction={playVideo}
            nextAction={nextLookbook}
            previousAction={previousLookbook}
            zoomAction={zoomVideo}
            unzoomAction={unzoomVideo}
            moreAction={goLexique}
            muteAction={muteSound}
          />
        )}
      </Transition>
    </div>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    introPoster: file(relativePath: { eq: "intro.png" }) {
      publicURL
    }
    introMP4: file(relativePath: { eq: "intro.mp4" }) {
      publicURL
    }
    introWebm: file(relativePath: { eq: "intro.webm" }) {
      publicURL
    }
    lookbooks: allMarkdownRemark(
      sort: { fields: frontmatter___order }
      filter: { fileAbsolutePath: { regex: "/lookbooks/" } }
    ) {
      nodes {
        frontmatter {
          id
          slug
          mp4 {
            publicURL
          }
          poster {
            publicURL
          }
          webm {
            publicURL
          }
        }
      }
    }
  }
`
