import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Transition, TransitionGroup } from "react-transition-group"
import posterURL from "@src/assets/images/intro.png"
import webmURL from "@src/assets/images/intro.webm"
import mp4URL from "@src/assets/images/intro.mp4"
import styled from "@emotion/styled"

// Styles
const Video = styled.video`
  height: 100%;
  width: auto;
  opacity: 0;

  background-color: #2d2c2d;

  .object-fit & {
    width: 100%;
    object-fit: cover;
  }
`

// Transition
const PlayerTransition = {
  entering: {
    opacity: 1,
    transition: `opacity 80ms ease-in 200ms`,
  },
  entered: {
    opacity: 1,
  },
  exiting: {
    transition: `opacity 80ms ease-out`,
  },
}

// Components
const PlayerVideo = props => {
  const videoRef = useRef()
  const [ready, setReady] = useState(false)
  const [size, setSize] = useState([0, 0])
  const { source, onEnded, onLoadeddata, play, loop, style, muted } = props

  useEffect(() => {
    let loaded = false
    const loadeddata = () => {
      if (loaded) return
      loaded = true
      onLoadeddata()
      setReady(true)
    }

    videoRef.current.addEventListener("loadeddata", loadeddata)
    videoRef.current.addEventListener("canplay", loadeddata)
    videoRef.current.addEventListener("loadedmetadata", loadeddata)
    videoRef.current.addEventListener("ended", onEnded)
    videoRef.current.onloadeddata = loadeddata

    return () => {
      videoRef.current.removeEventListener("loadeddata", loadeddata)
      videoRef.current.removeEventListener("canplay", loadeddata)
      videoRef.current.removeEventListener("loadedmetadata", loadeddata)
      videoRef.current.removeEventListener("ended", onEnded)
      videoRef.current.onloadeddata = undefined
    }
  }, [onEnded, onLoadeddata, setReady])

  useEffect(() => {
    if (ready && play) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }, [ready, play])

  useEffect(() => {
    const resize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener("resize", resize)
    resize()
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])

  return (
    <Video
      playsInline={true}
      preload="auto"
      style={{
        ...style,
        width: `${size[0]}px`,
        height: `${size[1]}px`,
      }}
      width={size[0]}
      height={size[1]}
      ref={videoRef}
      poster={source.poster}
      muted={muted}
      loop={loop}
      preload=""
    >
      {/*<source src={source.webm} type="video/webm" />*/}
      <source src={source.mp4} type="video/mp4" />
    </Video>
  )
}

const Player = props => {
  const {
    source,
    onEnded,
    onLoadeddata,
    play,
    onEntered,
    onEntering,
    timeout,
    loop,
    muted,
  } = props

  return (
    <TransitionGroup>
      <Transition
        key={source.mp4}
        timeout={timeout}
        onEntered={onEntered}
        onEntering={onEntering}
        appear={false}
      >
        {state => (
          <PlayerVideo
            style={PlayerTransition[state]}
            play={play}
            loop={loop}
            muted={muted}
            source={source}
            onEnded={onEnded}
            onLoadeddata={onLoadeddata}
          />
        )}
      </Transition>
    </TransitionGroup>
  )
}

Player.propTypes = {}

export default Player
