import React, { useCallback, useContext, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import ButtonSound from "@src/assets/images/button-sound.png"
import ButtonSoundOff from "@src/assets/images/button-sound-off.png"
import ButtonControls from "@src/assets/images/button-controls.png"
import ButtonSpace from "@src/assets/images/button-space.png"
import ButtonZoom from "@src/assets/images/button-zoom.png"
import ButtonTop from "@src/assets/images/button-top.png"
import ButtonRight from "@src/assets/images/button-right.png"
import ButtonBottom from "@src/assets/images/button-bottom.png"
import ButtonLeft from "@src/assets/images/button-left.png"
import { CursorContext } from "@src/contexts/CursorContext"
import { MediaContext } from "@src/contexts/MediaContext"
import sampleSoundURL from "@src/assets/sounds/6 - Boite a rythme Boutons .mp3"

const ControlsWrapper = styled.nav`
  position: relative;
  display: inline-block;
`

const ControlsButton = styled.button`
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 4px;
  outline: none;
  cursor: pointer;

  &.isDisabled {
    pointer-events: none;
    opacity: 0.2;
  }

  .no-touchevents & {
    opacity: 0.85;
    transition: opacity 200ms;
  }

  &:hover {
    opacity: 1;
  }

  img {
    height: 5vh;
    min-height: 30px;
  }

  &:nth-of-type(n + 2) {
    position: absolute;
  }
`

const noop = () => {}

const Controls = ({
  contolsAction = noop,
  pauseAction = noop,
  playAction = noop,
  nextAction = noop,
  previousAction = noop,
  zoomAction = noop,
  unzoomAction = noop,
  moreAction = noop,
  muteAction = noop,
  preorderAction = noop,
  disabled,
  muted,
  backLabel = "more info",
  controlLabel = "controls",
  preorderLabel = "shop",
  ...rest
}) => {
  const { setMessage } = useContext(CursorContext)
  const tf = useRef()

  const { playSample } = useContext(MediaContext)

  const onMouseEnter = useCallback(
    message => {
      setMessage(message)
    },
    [setMessage]
  )

  const onMouseLeave = useCallback(() => {
    setMessage("")
  }, [setMessage])

  useEffect(() => {
    let lock = false
    const onKeyDown = disabled
      ? noop
      : e => {
          if (lock) return
          lock = true
          switch (e.key) {
            case " ":
              pauseAction()
              break
            case "+":
              zoomAction()
              break
            case "ArrowRight":
              nextAction()
              break
            case "ArrowLeft":
              previousAction()
              break
            case "ArrowUp":
              moreAction()
              break
            case "ArrowDown":
              preorderAction()
              break
            case "ArrowDown":
              break
            case "c":
              contolsAction()
              break
            case "m":
              muteAction()
              break
          }
        }

    const onKeyUp = disabled
      ? noop
      : e => {
          lock = false
          switch (e.key) {
            case " ":
              playAction()
              break
            case "+":
              unzoomAction()
              break
          }
        }

    document.addEventListener("keydown", onKeyDown)
    document.addEventListener("keyup", onKeyUp)

    return () => {
      document.removeEventListener("keypress", onKeyDown)
      document.removeEventListener("keyup", onKeyUp)
    }
  }, [
    pauseAction,
    nextAction,
    playAction,
    contolsAction,
    zoomAction,
    unzoomAction,
    moreAction,
    preorderAction,
    muteAction,
    disabled,
  ])

  return (
    <>
      <ControlsWrapper
        onClick={() => playSample(sampleSoundURL)}
        className={disabled ? "isDisabled" : ""}
        {...rest}
      >
        <ControlsButton
          disabled={disabled}
          onMouseEnter={() => onMouseEnter("pause")}
          onMouseLeave={onMouseLeave}
          onMouseDown={pauseAction}
          onMouseUp={playAction}
          onTouchStart={disabled ? noop : pauseAction}
          onTouchEnd={disabled ? noop : playAction}
        >
          <img src={ButtonSpace} alt="action space" />
        </ControlsButton>
        <ControlsButton
          disabled={disabled}
          onMouseEnter={() => onMouseEnter("mute")}
          onMouseLeave={onMouseLeave}
          onClick={muteAction}
          style={{ bottom: "100%", right: "100%" }}
        >
          <img src={muted ? ButtonSoundOff : ButtonSound} alt="action sound" />
        </ControlsButton>
        <ControlsButton
          disabled={disabled}
          onMouseEnter={() => onMouseEnter(controlLabel)}
          onMouseLeave={onMouseLeave}
          onClick={disabled ? noop : contolsAction}
          style={{ bottom: 0, right: "100%" }}
        >
          <img src={ButtonControls} alt="action controls" />
        </ControlsButton>
        <ControlsButton
          disabled={disabled}
          onMouseEnter={() => onMouseEnter("zoom")}
          onMouseLeave={onMouseLeave}
          onMouseDown={disabled ? noop : zoomAction}
          onMouseUp={disabled ? noop : unzoomAction}
          onTouchStart={disabled ? noop : zoomAction}
          onTouchEnd={disabled ? noop : unzoomAction}
          style={{ bottom: 0, left: "100%" }}
        >
          <img src={ButtonZoom} alt="action zoom" />
        </ControlsButton>
        <ControlsButton
          disabled={disabled}
          onMouseEnter={() => onMouseEnter(backLabel)}
          onMouseLeave={onMouseLeave}
          onClick={disabled ? noop : moreAction}
          style={{ bottom: "200%", left: "52%", transform: "translateX(-50%)" }}
        >
          <img src={ButtonTop} alt="action top" />
        </ControlsButton>
        <ControlsButton
          disabled={disabled}
          onMouseEnter={() => onMouseEnter("next")}
          onMouseLeave={onMouseLeave}
          onClick={disabled ? noop : nextAction}
          style={{ bottom: "100%", right: "8%" }}
        >
          <img src={ButtonRight} alt="action right" />
        </ControlsButton>
        <ControlsButton
          disabled={disabled}
          onMouseEnter={() => onMouseEnter(preorderLabel)}
          onMouseLeave={onMouseLeave}
          onClick={disabled ? noop : preorderAction}
          style={{ bottom: "100%", left: "52%", transform: "translateX(-50%)" }}
        >
          <img src={ButtonBottom} alt="action bottom" />
        </ControlsButton>
        <ControlsButton
          disabled={disabled}
          onMouseEnter={() => onMouseEnter("previous")}
          onMouseLeave={onMouseLeave}
          onClick={disabled ? noop : previousAction}
          style={{ bottom: "100%", left: "11%" }}
        >
          <img src={ButtonLeft} alt="action left" />
        </ControlsButton>
      </ControlsWrapper>
    </>
  )
}

Controls.propTypes = {}

export default Controls
